<template>
    <article class="question" ref="card">
        <section class="text">
            {{ question.text }}
        </section>
        <section class="scale"></section>
    </article>
</template>

<script>
export default {
    name: 'question',
    props: ['question']
}
</script>

<style lang="scss">
    @import '../../../scss/variables';

    .question {
        width: 80vw;
        height: 120vw;
        background: white;
        display: flex;
        flex-direction: column;
        border-radius: $radius;
        will-change: transform;

        .text {
            flex: 4;
            padding: 2rem;
            @extend %center;
            font-weight: 700;
            font-size: 1.2rem;
            text-align: center;
        }

        .scale {
            flex: 1;
        }

    }
</style>
