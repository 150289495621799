<template>
    <main id="questions">
        <header class="instructions">
            <aside class="back" @click="abort">avbryt</aside>
            {{ activeCard+1 }} / {{ questions.length }}
        </header>
        <question
            ref="qcard"
            v-hammer:pan.horizontal="onPan" 
            v-hammer:panend="onPanEnd" 
            :question="questions[activeCard]"
        />
        <footer class="choice">
            <section class="no"  @click="no">&leftarrow; Stämmer ej</section>
            <section class="yes" @click="yes">Stämmer &rightarrow;</section>
        </footer>
    </main>
</template>

<script>

import question from './Question';
import anime from 'animejs';

export default {
    name: 'questions',
    components: {
        question
    },
    data(){
        return {
            deltaX:     0,
            activeCard: 0,
            direction:  String,
            threshold:  70,
            duration:   700
        }
    },
    computed: {
        questions(){
            return this.$store.getters.iceberg.questions;
        }
    },
    methods: {
        abort(){
            this.$emit('move', { transitionType: 'reveal', to: '/test/programtestet' });
        },
        onPan(e){

            this.deltaX = e.deltaX;
            
            if(e.direction === 2){
                this.direction = 'left';
            }

            if(e.direction === 4){
                this.direction = 'right';
            }   

            anime({
                targets: this.$refs.qcard.$el,
                translateX: this.deltaX,
                duration: 0,
                easing: 'linear'
            })

        },
        onPanEnd(){

            if(this.deltaX < this.threshold && this.deltaX > -this.threshold){ 
                
                anime({
                    targets: this.$refs.qcard.$el,
                    translateX: 0,
                    duration: 100,
                    easing: 'spring'
                });

            } else {

                this.flyOut();
                
            }
        },
        flyOut(){
            // If NOT last card, else go to results
            if( this.activeCard+2 < this.questions.length ) {
            
                let bounds;

                this.direction === 'right' ? bounds = window.innerWidth : bounds = -window.innerWidth; 

                // Update profile data
                if(this.direction == 'right'){ 
                    this.$store.commit('setIcebergResult', this.questions[this.activeCard]);
                }

                // increment active Question
                setTimeout(() => {
                    this.activeCard++                
                }, this.duration/5)

                // Reanimate component
                anime({
                    targets: this.$refs.qcard.$el,
                    translateX: [
                                    { value: bounds, duration: this.duration/5, easing: 'linear' },
                                    { value: 0, duration: 0             },
                                    { value: 0, duration: this.duration }
                                ],
                    opacity:    [
                                    { value: 1, duration: this.duration/5 },
                                    { value: 0, duration: 0             },
                                    { value: 1, duration: this.duration }
                                ],
                    scale:      [
                                    { value: 1, duration: this.duration/5 },
                                    { value: 0, duration: 0             },
                                    { value: 1, duration: this.duration }
                                ]
                })

            } else {
                this.$router.push('/test/programtestet/resultat');
            }

        },
        yes(){
            this.direction = 'right';
            this.flyOut();
        },
        no(){
            this.direction = 'left';
            this.flyOut();
        }
    }
}
</script>

<style lang="scss">
    @import '../../../scss/variables';

    #questions {
        @extend %center-column; 
    
        .instructions {
            @extend %center;
            color: rgba($color: #FFF, $alpha: .4);
            font-weight: 700;
            margin: 1rem 0;

            aside {
                position: absolute;
                top: 1rem;
                left: 1rem;
                padding: .25rem .5rem;
                border-radius: $radius;
                font-size: .8rem;
                border: 1px solid $lightgrey;
                color: $lightgrey;
            }
        }

        .choice {
            display: flex;
            width: 100%;
            padding: 1rem 2.4rem;

            section {
                flex: 1;
                @extend %center;
                color: white;
            }

        }
    }
</style>
